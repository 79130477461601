.enumeration {
  background-color: black;

  .wrapper {
    display: flex;
    max-width: 1160px;
    padding: 90px 0px;
    margin: 0 auto;
  }

  .container {
    display: flex;

    @media (max-width: 1250px) {
      margin: 0 auto;
    }

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  .title {
    font-weight: bold;
    font-size: 60px;
    line-height: 69px;
    color: #ffffff;
  }

  .red_line {
    width: 60px;
    height: 5px;
    background-color: red;
    margin-bottom: 40px;
  }

  .subtitle {
    font-family: "Ubuntu";
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    margin-bottom: 25px;
  }

  .container_text {
    font-family: "Ubuntu";
    color: #ffffff;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    max-width: 296px;
  }
}

.location {
  .wrapper {
    height: 684px;
    width: 100%;

    @media (max-width: 600px) {
      height: 350px;
    }
  }

  .title {
    max-width: 1160px;
    margin: 0px auto;
  }

  .heading {
    margin: 0px 0px 20px 30px;
    font-size: 40px;
    line-height: 46px;
    font-weight: 800;
  }

  .container {
    background-image: url("../../images/Map/map-yandex.jpg");
    width: 100%;
    height: 550px;
    filter: grayscale(100%);

    @media (max-width: 600px) {
      height: 280px;
    }
  }

  .red {
    color: red;
  }
}

.map {
  width: 100%;
}

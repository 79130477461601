.category {
  max-width: 1160px;
  margin: 0px auto;

  .wrapper {
    position: relative;
    padding-top: 28px;
    margin: 80px 0px 194px;

    @media (max-width: 1250px) {
      margin: 0;
      padding: 0;
    }
  }

  .title {
    max-width: 256px;
    margin-bottom: 28px;
    font-size: 60px;
    line-height: 69px;
    font-family: "Ubuntu";
    font-weight: bold;
  }

  .left_column {
    margin-right: 40px;

    @media (max-width: 650px) {
      margin-bottom: 17px;
    }
  }
}

.content {
  @media (max-width: 1250px) {
    width: 600px;
    margin: 0 auto;
  }

  @media (max-width: 650px) {
    width: 300px;
  }
}

.left_column_mr {
  margin-right: 224px;

  @media (max-width: 1250px) {
    margin-right: 80px;
  }

  @media (max-width: 800px) {
    margin-right: 0px;
    margin-bottom: 100px;
  }
}

.right_column {
  max-width: 250px;
}

.container {
  display: flex;

  @media (max-width: 650px) {
    flex-direction: column;
  }

  &_left {
    margin-top: 30px;
  }

  &_title {
    font-family: "Ubuntu";
    font-weight: 700;
    font-size: 60px;
    line-height: 69px;
    text-transform: capitalize;
    color: #000000;
    max-width: 500px;
    margin-bottom: 60px;
  }

  &_ul {
    margin-left: 40px;
    width: 100%;
  }

  &_li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 17px;
    }
  }

  &_img {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: -1;

    @media (max-width: 1250px) {
      width: 100%;
      position: relative;
      margin: 40px auto 0;
    }
  }

  &_logo {
    margin-right: 20px;
  }

  &_text {
    max-width: 310px;
    font-size: 15px;
    line-height: 17px;
  }

  &_belarus {
    width: 710px;
    height: 612px;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.terminals {
  background-color: #e7e7e7;

  .wrapper {
    max-width: 1160px;
    margin: 0px auto;
    padding: 88px 0px;

    @media (max-width: 1250px) {
      max-width: 600px;
    }

    @media (max-width: 600px) {
      max-width: 300px;
      padding: 30px 0px;
    }
  }

  .container {
    display: flex;
    position: relative;

    @media (max-width: 1250px) {
      flex-direction: column;
    }

    &_left {
      margin-top: 30px;
    }

    &_title {
      font-weight: 700;
      font-size: 60px;
      line-height: 69px;
      text-transform: capitalize;
      color: #000000;
      max-width: 500px;
      margin-bottom: 60px;

      @media (max-width: 600px) {
        font-size: 45px;
        line-height: 50px;
      }
    }

    &_ul {
      margin: 0 0 42px 40px;
      width: 100%;

      @media (max-width: 600px) {
        margin-left: 0;
      }
    }

    &_li {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 17px;
      }
    }

    &_logo {
      margin-right: 20px;
    }

    &_text {
      max-width: 240px;
      font-weight: 400;
      font-size: 15px;
      line-height: 17px;
      text-transform: capitalize;
      color: #000000;
    }

    &_belarus {
      position: absolute;
      width: 710px;
      height: 614px;
      right: 0px;

      @media (max-width: 1250px) {
        position: relative;
        width: 600px;
        height: 504px;
      }

      @media (max-width: 600px) {
        position: relative;
        width: 300px;
        height: 244px;
      }
    }
  }

  .link {
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #000000;
    margin-left: 40px;

    @media (max-width: 1250px) {
      margin-bottom: 60px;
    }
  }
}

.name_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px auto;
  padding-top: 40px;
  color: white;
  height: 1080px;
  z-index: 10;
  position: absolute;
  top: 0;
  margin-top: 314px;
  width: 100%;

  @media (max-width: 650px) {
    margin-top: 154px;
  }

  .line {
    width: 100px;
    border-bottom: 3px solid white;
    margin-bottom: 25px;

    @media (max-width: 650px) {
      border-bottom: 2px solid white;
      width: 65px;
    }
  }

  .wrapper {
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (max-width: 1250px) {
      max-width: 600px;
    }

    @media (max-width: 650px) {
      max-width: 300px;
    }
  }

  .link {
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    color: white;
    margin-top: 92px;

    @media (max-width: 600px) {
      margin-top: 20px;
      font-size: 15px;
    }
  }

  .subtitle {
    font-size: 20px;
    line-height: 23px;
    color: white;
    max-width: 376px;

    @media (max-width: 600px) {
      font-size: 16px;
      max-width: 250px;
    }
  }

  .title {
    font-family: "Ubuntu";
    font-weight: 800;
    font-size: 80px;
    line-height: 92px;
    margin-bottom: 25px;
    max-width: 900px;
    text-transform: capitalize;

    @media (max-width: 1250px) {
      font-size: 60px;
    }
    @media (max-width: 650px) {
      font-size: 36px;
      max-width: 300px;
      line-height: 54px;
    }
  }

  .red {
    color: red;
  }

  .title_terminals {
    font-family: "Ubuntu";
    font-weight: 800;
    font-size: 80px;
    line-height: 92px;
    margin-bottom: 25px;
    max-width: 636px;
    text-transform: capitalize;

    @media (max-width: 1250px) {
      font-size: 60px;
    }

    @media (max-width: 650px) {
      font-size: 39px;
      max-width: 300px;
      line-height: 54px;
    }
  }
}

.gallery {
  margin-bottom: 218px;

  @media (max-width: 600px) {
    margin-bottom: 100px;
  }

  .wrapper {
    display: flex;
    width: 1160px;
    height: 492px;
    align-items: center;
    margin: 0px auto;

    @media (max-width: 1159px) {
      width: 600px;
      height: 240px;
    }
    @media (max-width: 600px) {
      width: 390px;
      height: 200px;
    }
  }

  .block {
    width: 1160px;
    height: 492px;

    @media (max-width: 1250px) {
      width: 100%;
      height: 100%;
    }
  }
}

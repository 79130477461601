.map {
  height: 614px;
  width: 100%;
  margin: 0px auto;
  position: relative;

  .wrapper {
    margin: 0px auto;
    max-width: 1160px;
    width: 100%;

    @media (max-width: 1250px) {
      max-width: 600px;
    }
  }

  .title {
    margin: 0px 0px 20px 30px;
    font-weight: 700;
    text-transform: capitalize;
    color: #000000;
    max-width: 500px;
    font-size: 40px;
    line-height: 46px;
  }

  @media (max-width: 1250px) {
    height: 802px;
  }

  @media (max-width: 600px) {
    height: 706px;
  }
}

.yandex {
  background-image: url("../../images/Map/map-yandex.jpg");
  width: 100%;
  height: 550px;
  filter: grayscale(100%);

  @media (max-width: 1250px) {
    height: 350px;
    margin-bottom: 20px;
  }

  @media (max-width: 600px) {
    height: 250px;
  }
}

.yandex_map {
  width: 100%;
  height: 550px;
  z-index: 100;

  @media (max-width: 1250px) {
    display: none;
  }
}

.yandex_map_mobile {
  display: none;
  width: 100%;
  height: 350px;
  z-index: 100;

  @media (max-width: 1250px) {
    display: flex;
  }

  @media (max-width: 600px) {
    height: 250px;
  }
}

.accordeon {
  width: 500px;
  right: 360px;
  top: 138px;
  position: absolute;

  @media (max-width: 1500px) {
    right: 200px;
  }

  &_wrapper {
    width: 500px;

    @media (max-width: 1250px) {
      width: 500px;
      margin: 0 auto;
    }
  }

  @media (max-width: 1250px) {
    position: initial;
    margin: 0 auto;
  }
}

.header {
  display: flex;
  height: 50px;
  background-color: black;
  align-items: center;
  position: relative;

  &_title {
    font-family: "Ubuntu";
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    margin: 0 0 0 14px;
  }

  &_img {
    position: absolute;
    right: 20px;

    @media (max-width: 600px) {
      right: 100px;
    }
  }
}

.active_header {
  background-color: #e7e7e7;
  color: black;

  &_title {
    color: black;
  }

  &_img {
    right: 50px;

    @media (max-width: 600px) {
      right: 130px;
    }
  }
}

.img_plus {
  height: 30px;
  width: 30px;
  margin-left: 278px;
}

.img_minus {
  padding-bottom: 6px;
}

.body {
  height: 0;
  min-height: 0;
  overflow: hidden;
  background-color: #ffffff;
  transition: all 0.4s ease-in-out;

  &_padding {
    padding: 38px 0 38px 46px;
  }

  &_address {
    max-width: 248px;
    font-size: 15px;
    margin-bottom: 6px;
  }

  &_title {
    max-width: 248px;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 800;
  }

  &_tel {
    max-width: 248px;
    margin-bottom: 6px;
  }
}

.color_red {
  color: red;
}

.active {
  min-height: 200px;
}

.hidden {
  display: none;
  visibility: inherit;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  background-color: black;
  background-position: center center;
  padding: 30px 0px;
  color: white;

  @media (max-width: 1250px) {
    flex-direction: column;
  }

  .logo {
    margin-right: 518px;

    @media (max-width: 1250px) {
      margin-right: 0;
      margin-bottom: 40px;
    }
  }

  .wrapper {
    display: flex;
    max-width: 1160px;
    justify-content: space-between;

    @media (max-width: 1250px) {
      max-width: 600px;
    }
  }

  .navigate {
    display: flex;

    @media (max-width: 1250px) {
      flex-direction: column;
      align-items: center;
    }
  }
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu";
}

p {
  margin: 0;
}
